<template>
  <div class="video-wrapper flex-col cntr" :style="backgroundColorStyle">
    <div :class="['video-content-wrapper', { 'black-bg': isBlackBg }]">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="content" v-html="content" />
    </div>
    <!-- use metadata (first frame of video) instead of poster because poster can't be resized, resulting is large (800k image)-->
    <!-- :poster="videoPoster" -->
    <div class="video-container">
      <video
        ref="videoRef"
        class="video"
        :preload="preload ? 'auto' : 'metadata'"
        v-bind="videoPlayStyle"
        :poster="videoPosterUrl"
      >
        <template v-if="videoType === 'cloudinary'">
          <source :src="videoUrl" />
        </template>
        <template v-if="videoType === 'cms'">
          <source v-for="(format, key) in videoObject.formats" :key="key" :src="format.url" :type="(key as string)" />
        </template>
      </video>
      <!-- <button ref="playButton" class="play-button" @click="playButtonClick()" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { VideoHTMLAttributes } from 'nuxt/dist/app/compat/capi';
import { File } from '@solo-stove/types/contentstack';
import { getFirstIndex } from '../util/arrays';
import { AssetCloudinary } from '~/types/contentstack';
import { useCloudinary } from '~/composables/cloudinary';

const cloudinary = useCloudinary();
const props = defineProps({
  title: {
    type: String,
    default: '',
    required: false,
  },
  content: {
    type: String,
    required: false,
    default: '',
  },
  // eslint-disable-next-line vue/prop-name-casing
  cms_asset: {
    type: Object as () => {
      assetConnection: {
        edges: Array<{ node: File }>;
      };
      posterConnection: {
        edges: Array<{ node: File }>;
      };
    },
    required: true,
  },
  asset: {
    type: Array as () => AssetCloudinary[],
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
    default: () => {
      return {
        color: '',
      };
    },
  },
  // eslint-disable-next-line vue/prop-name-casing
  display_as_gif: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: Boolean,
    default: false,
  },
});

// const videoRef = ref<HTMLVideoElement | null>(null);
// const playButton = ref<HTMLButtonElement | null>(null);

const video = computed<any>(() => getFirstIndex(props.asset));
const backgroundColorStyle = computed<string | undefined>(() => {
  return props.background_color?.color ? `background-color: ${props.background_color.color}` : undefined;
});
// const videoPoster = computed<string | undefined>(() => cloudinary.generateImageUrl(video.value?.public_id));
const videoPosterUrl = computed<string | undefined>(() => {
  if (videoType === 'cloudinary') {
    return video.value?.public_id
      ? cloudinary.generateVideoUrl(`${video.value?.public_id}.jpg`, { width: 1200, height: 670, thumbnail: true })
      : '';
  } else {
    return videoPoster?.value?.url;
  }
});
const videoUrl = computed<string | undefined>(() =>
  cloudinary.generateVideoUrl(video.value?.public_id, { width: 1200, height: 670 })
);
const isBlackBg = computed<boolean>(
  () => props.background_color?.color === '#1a1a1a' || props.background_color?.color === '#000000'
);
const videoFormats = computed(() => {
  const obj: { [index: string]: File } = {};
  const videoFiles = props.cms_asset?.assetConnection?.edges || [];
  if (!videoFiles.length) {
    return null;
  }
  videoFiles.forEach((edge) => {
    const key = edge?.node?.content_type;
    const value = edge?.node;
    obj[key] = value;
  });
  return obj;
});
const videoPoster = computed(() => props.cms_asset?.posterConnection?.edges?.[0]?.node);
const videoObject = computed(() => {
  return {
    formats: videoFormats.value,
    poster: {
      format: videoPoster?.value?.content_type,
      url: videoPoster?.value?.url,
    },
  };
});
const videoType = videoFormats.value && videoPoster.value?.url ? 'cms' : 'cloudinary';
const videoPlayStyle = computed(() => {
  if (props.display_as_gif) {
    return {
      controls: 'false',
      autoplay: 'true',
      loop: 'true',
      muted: 'true',
      playsinline: 'true',
    } as VideoHTMLAttributes;
  }
  const attrs: VideoHTMLAttributes = {
    controls: 'true',
    playsinline: 'true',
  };
  if (videoPoster.value?.url) {
    attrs.poster = videoPoster.value?.url;
  }
  return attrs;
});

// function videoClick() {
//   if (playButton?.value) {
//     playButton.value.style.display = 'none';
//   }
// }

// function playButtonClick() {
//   if (videoRef?.value?.paused && playButton?.value) {
//     videoRef.value.play();
//     playButton.value.style.display = 'none';
//   }
// }
</script>

<style lang="scss" scoped>
.video-wrapper {
  display: flex;
  text-align: center;

  .video-container {
    position: relative;
    width: 100%;
    padding-top: 1rem;
    @include local-mixins.desktop {
      padding: 1.5rem 6rem 3.5rem 6rem;
    }
    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 70px;
      background-color: $color-primary-500;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
      @include local-mixins.desktop {
        width: 104px;
        height: 104px;
      }
      &:hover {
        background-color: $color-primary-600;
      }
      &:focus {
        outline: none;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 22px solid white;
        @include local-mixins.tablet_and_mobile {
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 12px solid white;
        }
      }
    }
  }

  .video {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .video-content-wrapper {
    max-width: 768px;
    &.black-bg {
      color: $color-neutral-white;
    }
  }
  @include local-mixins.xxl {
    padding: 0 2rem;
  }
  @include local-mixins.tablet_and_mobile {
    padding: 0 1rem;
  }
}
.layout-flush {
  .video-container {
    padding: 0;
  }
}
</style>
